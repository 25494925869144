import React, { useState, useEffect } from 'react'
import styles from './Page.module.scss'
import Content from '../singlePost/Content'
import { Link } from 'gatsby'
import _ from 'lodash'

const Page = ({ title, excerpt, relevantTerms, body }) => {
  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div>
            <div>
              <div className={styles.headerContent}>
                <div className={styles.heading}>
                  <div className={styles.breadcrumb}>
                    <Link to="/glossary/">Glossary</Link> /{' '}
                    <Link to={`/glossary/${_.kebabCase(title)}/`}>{title}</Link>
                  </div>
                </div>
              </div>
              <div className={styles.articleContent}>
                <article itemScope itemType="http://schema.org/BlogPosting">
                  <h1>{title}</h1>
                  <p>{excerpt}</p>
                  <Content source={body} />
                  {relevantTerms?.length > 0 && (
                    <div className={styles.relevantTerms}>
                      <h2>Relevant Terms</h2>
                      {relevantTerms.map(term => (
                        <Link to={`/glossary/${_.kebabCase(term)}/`}>
                          <span className={styles.term}>{term}</span>
                        </Link>
                      ))}
                    </div>
                  )}
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Page
